// eslint-disable-next-line import/no-extraneous-dependencies
import agent from 'superagent';
// eslint-disable-next-line import/no-extraneous-dependencies
import lo from 'lodash';

import config from './config';
import authService from './client.service';
import authHeader from '../helpers/auth-headers';

function getAll() {
  const token = authHeader();
  return agent.get(`${config.host}/tasks`)
    .set('Authorization', token)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function createTask(payload) {
  const token = authHeader();
  return agent.post(`${config.host}/tasks`)
    .set('Authorization', token)
    .set('Content-type', 'application/json')
    .send(payload)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function updateTask(data) {
  const uuid = data.id;
  const payload = data.payload || {};
  if (!uuid) throw new Error('UUID is empty');
  const token = authHeader();
  return agent.put(`${config.host}/tasks/${uuid}`)
    .set('Authorization', token)
    .set('Content-type', 'application/json')
    .send(payload)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

export default {
  getAll,
  createTask,
  updateTask,
};
