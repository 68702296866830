// eslint-disable-next-line import/no-extraneous-dependencies
import agent from 'superagent';
// eslint-disable-next-line import/no-extraneous-dependencies
import lo from 'lodash';

import config from './config';
import authService from './client.service';
import authHeader from '../helpers/auth-headers';

function getAll(skip, limit = 100) {
  const token = authHeader();
  return agent.get(`${config.host}/channels`)
    .query({
      skip: skip || 0,
      limit,
    })
    .set('Authorization', token)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function getAttributes(uuid) {
  const token = authHeader();
  return agent.get(`${config.host}/attributes/${uuid}`)
    .set('Authorization', token)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function createChannel(payload) {
  const token = authHeader();
  return agent.post(`${config.host}/channels`)
    .set('Authorization', token)
    .set('Content-type', 'application/json')
    .send(payload)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function updateChannel(id, payload) {
  const token = authHeader();
  return agent.put(`${config.host}/channels/${id}`)
    .set('Authorization', token)
    .set('Content-type', 'application/json')
    .send(payload)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function createAttr(payload) {
  const token = authHeader();
  return agent.post(`${config.host}/attributes`)
    .set('Authorization', token)
    .set('Content-type', 'application/json')
    .send(payload)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function updateAttr(id, payload) {
  const token = authHeader();
  return agent.put(`${config.host}/attributes/${id}`)
    .set('Authorization', token)
    .set('Content-type', 'application/json')
    .send(payload)
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

function removeAttr(id) {
  const token = authHeader();
  return agent.delete(`${config.host}/attributes/${id}`)
    .set('Authorization', token)
    .set('Content-type', 'application/json')
    .then(({ body }) => body.data || [])
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        authService.logout(true);
      }
      return Promise.reject(message);
    });
}

export default {
  getAll,
  getAttributes,
  createChannel,
  createAttr,
  updateAttr,
  removeAttr,
  updateChannel,
};
