import taskService from '../services/task.service';

const tasks = {
  namespaced: true,
  state: {
    pending: false,
    all: {},
  },
  actions: {
    getAll({ commit }) {
      commit('getAllRequest');
      taskService.getAll()
        .then(
          (response) => commit('getAllSuccess', response),
          (error) => commit('getAllFailure', error),
        );
    },
    createTask({ commit, dispatch }, payload) {
      commit('createTaskRequest');
      taskService.createTask(payload)
        .then(
          (response) => {
            commit('createTaskSuccess', { data: response.uuid });
            dispatch('alert/success', `Создан успешно: ${response.uuid}`, { root: true });
            dispatch('getAll');
          },
          (error) => {
            commit('createTaskFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
    updateTask({ commit, dispatch }, payload) {
      commit('createTaskRequest');
      taskService.updateTask(payload)
        .then(
          (response) => {
            commit('createTaskSuccess', { data: response.uuid });
            dispatch('alert/success', `Создан успешно: ${response.uuid}`, { root: true });
            dispatch('getAll');
          },
          (error) => {
            commit('createTaskFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
  },
  mutations: {
    getAllRequest(state) {
      state.all = { loading: true };
    },
    getAllSuccess(state, items) {
      state.all = { items };
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
    createTaskRequest(state) {
      state.pending = true;
    },
    createTaskSuccess(state) {
      state.pending = false;
    },
    createTaskFailure(state) {
      state.pending = false;
    },
  },
};

export default tasks;
