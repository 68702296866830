// eslint-disable-next-line import/no-extraneous-dependencies
import lo from 'lodash';

import userService from '../services/client.service';
import router from '../router';

const userInit = localStorage.getItem('user');
const initialState = userInit
  ? { status: { loggedIn: true }, user: lo.get(JSON.parse(userInit), 'user', null) }
  : { status: { loggedIn: false }, user: null };

const authentication = {
  namespaced: true,
  state: initialState,

  actions: {
    login(
      { dispatch, commit },
      { username, password },
    ) {
      commit('loginRequest', { username });

      userService.login(username, password)
        .then(
          (user) => {
            commit('loginSuccess', user);
            router.push('/home');
          },
        )
        .catch(
          (error) => {
            commit('loginFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
    logout({ commit }, withReload = false) {
      userService.logout(withReload);
      commit('logout');
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
};

export default authentication;
