<template>
  <header>
    <div style="margin: 20px">
      <h1>Finbro CRM</h1>
    </div>
    <div class="userbar">
      <div v-if="auth.status.loggedIn">
        {{auth.user.username}}
        <button v-on:click="logout()">Выход</button>
      </div>
      <div v-else>
        <router-link class="navbar" to="/login">Вход</router-link>
      </div>
    </div>
  </header>
  <nav>
<!--    <router-link class="navbar" to="/home">Home</router-link>-->
    <router-link class="navbar" to="/about">FAQ</router-link>
    <router-link v-if="!auth.status.loggedIn" class="navbar" to="/login">
      Войти
    </router-link>
    <router-link v-if="scopes.includes('partner_api')" class="navbar" to="/tasks">
      Задачи
    </router-link>
    <router-link v-if="scopes.includes('private_api')" class="navbar" to="/partners">
      Партнеры
    </router-link>
    <router-link v-if="scopes.includes('private_api')" class="navbar" to="/channels">
      Каналы
    </router-link>
    <router-link v-if="scopes.includes('private_api')" class="navbar" to="/clients">
      Клиенты
    </router-link>
  </nav>
<!--  <button v-on:click="come()">HERE</button>-->
  <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
  <router-view/>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import lo from 'lodash';

export default {
  name: 'app',
  methods: {
    come() {
      console.log(this.$store.state);
    },
    logout() {
      this.$store.dispatch('authentication/logout', true);
    },
  },
  computed: {
    alert() {
      return this.$store.state.alert || {};
    },
    auth() {
      return this.$store.state.authentication || { status: { loggedIn: false } };
    },
    scopes() {
      return this.$store.state.authentication?.user?.scopes || [];
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.$store.dispatch('alert/clear');
      }
    },
  },
};
</script>

<style>
/*nav {*/
/*  padding: 30px;*/
/*}*/

/*nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  justify-content: space-between;
  background-color: darkslategrey;
  color: white;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: grey;
}

.navbar {
  color: white;
  margin: 10px;
}

nav a.router-link-exact-active {
  color: #42b983;
}

a {
  text-decoration: none;
}

.commands {
  padding: 10px;
  background-color: lightgrey;
}

.alert {
  background-color: black;
}

.alert-danger {
  color: red;
}

.alert-success {
  color: green;
}

.table-nav {
  margin: 2px;
  border-radius: 5px;
  border: solid;
}

.userbar {
  margin: 20px;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.form-center {
  justify-content: center;
}

.button-right {
  justify-content: right;
}

</style>
