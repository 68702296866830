// eslint-disable-next-line import/no-extraneous-dependencies
import agent from 'superagent';
// eslint-disable-next-line import/no-extraneous-dependencies
import lo from 'lodash';

import config from './config';

function logout(withReload = false) {
  localStorage.removeItem('user');
  if (withReload) {
    // window.location.reload();
    console.log(withReload);
  }
}

function login(username, password) {
  return agent.post(`${config.host}/auth`)
    .set('Content-Type', 'application/json')
    .send(JSON.stringify({ username, password }))
    .then(({ body }) => {
      localStorage.setItem('user', JSON.stringify(body.data || {}));
      return body.data.user || {};
    })
    .catch((err) => {
      const message = lo.get(err, 'response.body.error', err.message);
      if ([401, 403].includes(err.status)) {
        logout();
        // window.location.reload();
      }
      return Promise.reject(message);
    });
}

export default {
  login,
  logout,
};
