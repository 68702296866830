import { createStore } from 'vuex';

import alert from './Alert.module';
import authentication from './Auth.module';
import channel from './Channel.module';
import task from './Task.module';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    authentication,
    channel,
    task,
  },
});
