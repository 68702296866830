import channelService from '../services/channel.service';

const channels = {
  namespaced: true,
  state: {
    pending: false,
    all: {},
    attr: {
      loading: false,
      attributes: new Map(),
      error: '',
    },
  },
  actions: {
    getAll({ commit }, { skip = 0, limit = 100 }) {
      commit('getAllRequest');
      channelService.getAll(skip, limit)
        .then(
          (response) => commit('getAllSuccess', response),
          (error) => commit('getAllFailure', error),
        );
    },
    getAttributes({ commit }, { uuid }) {
      commit('getAttributesRequest');
      channelService.getAttributes(uuid)
        .then(
          (response) => commit('getAttributesSuccess', {
            uuid,
            data: response.attributes,
          }),
          (error) => commit('getAttributesFailure', error),
        );
    },
    createChannel({ commit, dispatch }, payload) {
      commit('createChannelRequest');
      channelService.createChannel(payload)
        .then(
          (response) => {
            commit('createChannelSuccess', { data: response.uuid });
            dispatch('alert/success', `Создан успешно: ${response.uuid}`, { root: true });
            dispatch('getAll');
          },
          (error) => {
            commit('createChannelFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
    updateChannel({ commit, dispatch }, { id, payload }) {
      commit('createChannelRequest');
      channelService.updateChannel(id, payload)
        .then(
          (response) => {
            commit('createChannelSuccess', { data: response.uuid });
            dispatch('alert/success', `Канал успешно изменен: ${response.uuid}`, { root: true });
            dispatch('getAll');
          },
          (error) => {
            commit('createChannelFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
    createAttr({ commit, dispatch }, payload) {
      commit('createAttrRequest');
      channelService.createAttr(payload)
        .then(
          (response) => {
            commit('createAttrSuccess', { data: response.uuid });
            dispatch('alert/success', `Создан успешно: ${response.name}`, { root: true });
            dispatch('getAttributes', { uuid: payload.channel });
          },
          (error) => {
            commit('createAttrFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
    updateAttr({ commit, dispatch }, { id, payload, channel }) {
      if (!id) {
        dispatch('alert/error', 'id is required', { root: true });
        return;
      }
      commit('updateAttrRequest');
      channelService.updateAttr(id, payload)
        .then(
          (response) => {
            commit('updateAttrSuccess', { data: response.uuid });
            dispatch('alert/success', `Изменен успешно: ${response.name}`, { root: true });
            dispatch('getAttributes', { uuid: channel });
          },
          (error) => {
            commit('updateAttrFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
    removeAttr({ commit, dispatch }, { id, channel }) {
      if (!id) {
        dispatch('alert/error', 'id is required', { root: true });
        return;
      }
      commit('updateAttrRequest');
      channelService.removeAttr(id)
        .then(
          (response) => {
            commit('updateAttrSuccess', { data: response.uuid });
            dispatch('alert/success', 'Удален успешно', { root: true });
            dispatch('getAttributes', { uuid: channel });
          },
          (error) => {
            commit('updateAttrFailure', error);
            dispatch('alert/error', error, { root: true });
          },
        );
    },
  },
  mutations: {
    getAllRequest(state) {
      state.all = { loading: true };
    },
    getAllSuccess(state, items) {
      state.all = { items };
    },
    getAllFailure(state, error) {
      state.all = { error };
    },
    getAttributesRequest(state) {
      state.attr.loading = true;
    },
    getAttributesSuccess(state, response) {
      state.attr.attributes.set(response.uuid, response.data);
      state.attr.loading = false;
    },
    getAttributesFailure(state, error) {
      state.attr.loading = false;
      state.attr.error = error;
    },
    createChannelRequest(state) {
      state.pending = true;
    },
    createChannelSuccess(state) {
      state.pending = false;
    },
    createChannelFailure(state) {
      state.pending = false;
    },
    createAttrRequest(state) {
      state.pending = true;
    },
    createAttrSuccess(state) {
      state.pending = false;
    },
    createAttrFailure(state) {
      state.pending = false;
    },
    updateAttrRequest(state) {
      state.pending = true;
    },
    updateAttrSuccess(state) {
      state.pending = false;
    },
    updateAttrFailure(state) {
      state.pending = false;
    },
  },
};

export default channels;
