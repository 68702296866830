import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import(/* webpackChunkName: "channel" */ '../views/TasksView.vue'),
  },
  {
    path: '/task/:id',
    name: 'task',
    component: () => import(/* webpackChunkName: "channel" */ '../views/TaskEditView.vue'),
  },
  {
    path: '/channels',
    name: 'channels',
    component: () => import(/* webpackChunkName: "channels" */ '../views/ChannelsView.vue'),
  },
  {
    path: '/channel/:id',
    name: 'channel',
    component: () => import(/* webpackChunkName: "channel" */ '../views/ChannelEditView.vue'),
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientsView.vue'),
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import(/* webpackChunkName: "channel" */ '../views/PartnersView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/about', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  return next();
});

export default router;
